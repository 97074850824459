import React, { useEffect, useState, lazy, Suspense } from 'react';
import RenderLoader from './common/RenderLoader';
import SectionSubTitle from './SectionSubTitle';
import Text from './Text';
import Numbers from './Numbers';
import { apiUrl } from '../utils/constants';

const LazyLoad = lazy(() => import('react-lazyload'));

const axios = require('axios');

const AboutSub = () => {
  const [data, setData] = useState([]);
  const [preambles, setPreambles] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      axios.get(`${apiUrl}text_icon&section_id=2`).then((result) => {
        setData(result.data);
      });
    } else if (preambles.length === 0) {
      axios
        .get(`${apiUrl}preambles&section_id=2&page=text_icon`)
        .then((result) => {
          setPreambles(result.data[0]);
        });
    }
  });

  return (
    <Suspense fallback={RenderLoader()}>
      <LazyLoad>
        <section id="about-sub">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-9 wp3 delay-1s animated fadeInDown">
                <SectionSubTitle headingText={preambles.heading_1} />

                <ul className="feature-list">
                  <Text iconList={data} />
                </ul>
              </div>

              <div className="col-lg-3 col-md-3" id="counters">
                <ul className="counters wp3 animated fadeInDown">
                  <Numbers />
                </ul>
              </div>
            </div>
          </div>
        </section>
      </LazyLoad>
    </Suspense>
  );
};

export default AboutSub;
