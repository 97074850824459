import React, { useEffect, useState } from 'react';
import SectionTitle from './SectionTitle';
import TextCarousel from './TextCarousel';

import { apiUrl } from '../utils/constants';

const axios = require('axios');

const Reviews = () => {
  const myInput = React.createRef();

  const [data, setData] = useState([]);
  const [preambles, setPreambles] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      axios.get(`${apiUrl}testimonials`).then((result) => {
        setData(result.data);
      });
    } else if (preambles.length === 0) {
      axios.get(`${apiUrl}preambles&section_id=4`).then((result) => {
        setPreambles(result.data[0]);
      });
    } else {
      const section = myInput.current;

      if (section !== null) {
        $('#owl-reviews').owlCarousel({
          navigation: true,
          pagination: true,
          slideSpeed: 300,
          paginationSpeed: 400,
          singleItem: true,
        });
      }
    }
  });

  return (
    <section id="reviews" ref={myInput}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 wp10 delay-2s animated fadeInDown">
            <SectionTitle headingText={preambles.heading_1} />

            <div id="owl-reviews">
              <TextCarousel span="true" textSlide={data} customClass="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
