import React, { useEffect, useState, lazy, Suspense } from 'react';
import RenderLoader from './common/RenderLoader';
import ImgUrl from './ImgUrl';
import SectionTitle from './SectionTitle';

import { apiUrl, siteUrl } from '../utils/constants';

const axios = require('axios');

const LazyLoad = lazy(() => import('react-lazyload'));

const Gallery = () => {
  const myInput = React.createRef();
  const [data, setData] = useState([]);
  const [bgData, setBgData] = useState([]);
  const [preambles, setPreambles] = useState([]);

  const animateClass = () => {
    const section = myInput.current;

    if (section != null) {
      $('#owl-gallery').owlCarousel({
        itemsCustom: [
          [0, 1],
          [450, 1],
          [600, 1],
          [700, 1],
          [1000, 2],
          [1200, 3],
          [1400, 3],
          [1600, 3],
        ],
        navigation: true,
        pagination: true,
        afterInit(elem) {
          const that = this;
          that.owlControls.prependTo(elem);
        },
      });

      $('.popup').magnificPopup({
        type: 'image',
        fixedContentPos: false,
        gallery: {
          enabled: true,
        },

        fixedBgPos: false,

        removalDelay: 800,
        mainClass: 'mfp-fade',
      });
    }
  };

  useEffect(() => {
    if (data.length === 0) {
      axios.get(`${apiUrl}gallery`).then((result) => {
        setData(result.data);
      });
    } else if (bgData.length === 0) {
      axios.get(`${apiUrl}background&section_id=6`).then((result) => {
        setBgData(result.data[0]);
      });
    } else if (preambles.length === 0) {
      axios.get(`${apiUrl}preambles&section_id=6`).then((result) => {
        setPreambles(result.data[0]);
      });
    } else {
      window.addEventListener('scroll', animateClass);

      return () => {
        window.removeEventListener('scroll', animateClass);
      };
    }
  });

  if (bgData.length === 0) {
    return '';
  }

  return (
    <Suspense fallback={RenderLoader()}>
      <LazyLoad>
        <section
          id="gallery"
          ref={myInput}
          style={{
            background: `url(${siteUrl}${bgData.img}) center center no-repeat`,
            backgroundSize: 'cover',
            borderBottom: '2px solid #fb0000',
          }}
        >
          <div className="overlay" />

          <div className="section-wrapper wp8 delay-05s animated fadeInDown">
            <SectionTitle headingText={preambles.heading_1} />
            <div id="owl-gallery">
              <ImgUrl imgLinks={data} />
            </div>
          </div>
        </section>
      </LazyLoad>
    </Suspense>
  );
};

export default Gallery;
