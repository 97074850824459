import React, { useEffect, useState } from 'react';
import Img from './Img';

import { apiUrl, siteUrl } from '../utils/constants';

const axios = require('axios');

const Author = () => {
  const [data, setData] = useState([]);
  const [preambles, setPreambles] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      axios.get(`${apiUrl}services`).then((result) => {
        setData(result.data[0]);
      });
    } else if (preambles.length === 0) {
      axios.get(`${apiUrl}preambles&section_id=3`).then((result) => {
        setPreambles(result.data[0]);
      });
    }
  });

  return (
    <section id="bio">
      <div className="container">
        <h1 id="bio_title" className="section-title">
          {preambles.heading_1}
        </h1>

        <div className="colored-line" />
        <div className="col-md-4 advantages-item wp7 animated fadeInDown">
          <Img src={`${siteUrl}${data.img}`} height={245} />
        </div>
        <div className="col-md-8 advantages-item wp7 animated fadeInDown">
          <p className="white_text justified_text">{data.text_1}</p>
        </div>
      </div>
    </section>
  );
};

export default Author;
