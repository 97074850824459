import React from 'react';
import Img from './Img';
import { pdfUrl, siteUrl } from '../utils/constants';

const BlogItem = ({ blogList }) =>
  blogList.map((item, index) => (
    <div key={`blog${index}${new Date().getTime()}`} className="item">
      <div className="prev-blog-item white_text">
        <h3>{item.heading_1}</h3>
        <p>
          {item.text_1.split('<br/>').map((crrStr, subIndex) => (
            <React.Fragment
              key={`fragment${
                index + subIndex + Math.random()
              }${new Date().getTime()}`}
            >
              <br />
              {crrStr.replace('<br>', '')}
            </React.Fragment>
          ))}
        </p>

        {item.img === '0' ? (
          <a
            href={`${pdfUrl}${item.pdf}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View
          </a>
        ) : (
          ''
        )}
      </div>

      <div className="blog-item">
        <div className="blog-item-img">
          {item.img !== '0' ? (
            <Img src={`${siteUrl}${item.img}`} alt="user's blog picture" />
          ) : (
            <Img
              title="pdf"
              src={`${siteUrl}${item.pdf.replace('pdf', 'jpg')}`}
              alt="user's blog pdf"
            />
          )}
        </div>
      </div>

      <div className="next-blog-item white_text">
        <h3>{item.heading_1}</h3>
        <p>
          {item.text_1.split('<br/>').map((crrStr) => (
            <React.Fragment
              key={`fragment${index + Math.random()}${new Date().getTime()}`}
            >
              <br />
              {crrStr.replace('<br>', '')}
            </React.Fragment>
          ))}
        </p>
        {item.img === '0' ? (
          <a
            href={`${pdfUrl}${item.pdf}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View
          </a>
        ) : (
          ''
        )}
      </div>
    </div>
  ));

export default BlogItem;
