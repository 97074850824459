import React from 'react';

const SectionTitle = ({
  className = '',
  headingText = '',
  animationStyle = '',
  span,
  headingTwo,
  icon,
}) => {
  const lastWord = (userStr) => {
    const newUserStr = userStr.split(' ');
    return newUserStr[newUserStr.length - 1];
  };

  if (span) {
    return (
      <h1 className={`${className} section-title ${animationStyle}`}>
        {headingText !== undefined
          ? headingText.replace(lastWord(headingText), '')
          : ''}{' '}
        {span ? (
          <span className="text-color font-light">
            {headingText !== undefined ? lastWord(headingText) : ''}
          </span>
        ) : (
          ''
        )}
      </h1>
    );
  }
  if (headingTwo) {
    return (
      <h2>
        {headingText ? headingText.replace(lastWord(headingText), '') : ''}
        <span className="text-color">
          {headingText ? lastWord(headingText) : ''}
        </span>
      </h2>
    );
  }
  if (icon) {
    return (
      <h1 className={`${className} section-title ${animationStyle}`}>
        {headingText}

        <i className="fa fa-calendar-o" />
      </h1>
    );
  }
  return (
    <h1 className={`${className} section-title ${animationStyle}`}>
      {headingText}
    </h1>
  );
};

export default SectionTitle;
