import React, { useEffect, useState, lazy, Suspense } from 'react';
import RenderLoader from './common/RenderLoader';
import SectionTitle from './SectionTitle';
import BlogItem from './BlogItem';
import { apiUrl } from '../utils/constants';

const axios = require('axios');

const LazyLoad = lazy(() => import('react-lazyload'));

const Blog = () => {
  const myInput = React.createRef();
  const [data, setData] = useState([]);

  const animateClass = () => {
    $('#owl-blog').owlCarousel({
      navigation: true,
      slideSpeed: 300,
      paginationSpeed: 400,
      singleItem: true,

      // Pagination
      pagination: true,
    });
  };

  useEffect(() => {
    if (data.length === 0) {
      axios.get(`${apiUrl}blog`).then((result) => {
        setData(result.data);
      });
    } else {
      window.addEventListener('scroll', animateClass);

      return () => {
        window.removeEventListener('scroll', animateClass);
      };
    }
  });

  return (
    <Suspense fallback={RenderLoader()}>
      <LazyLoad>
        <div>
          <section id="blog" ref={myInput}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 wp9 delay-1s animated fadeInDown">
                  <SectionTitle headingText="" icon />
                  <div id="owl-blog">
                    <BlogItem blogList={data} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </LazyLoad>
    </Suspense>
  );
};

export default Blog;
