import React, { useEffect, useState, lazy, Suspense } from 'react';
import RenderLoader from './common/RenderLoader';
import Accordion from './Accordion';
import SectionSubTitle from './SectionSubTitle';
import Slider from './Slider';
import { apiUrl } from '../utils/constants';

const LazyLoad = lazy(() => import('react-lazyload'));

const axios = require('axios');

const Features = () => {
  const [preambles, setPreambles] = useState([]);

  useEffect(() => {
    if (preambles.length === 0) {
      axios
        .get(`${apiUrl}preambles&section_id=2&page=slider_bar`)
        .then((result) => {
          setPreambles(result.data[0]);
        });
    }
  });

  return (
    <Suspense fallback={RenderLoader()}>
      <LazyLoad>
        <section id="features">
          <div className="container">
            <div className="row">
              <div
                id="expand-counters"
                className="col-md-6 features wp4 delay-1s animated fadeInUp"
              >
                <SectionSubTitle headingText={preambles.heading_1} />
                <ul className="features-wrap">
                  <Slider />
                </ul>
              </div>

              <div className="col-md-6 wp4 delay-2s animated fadeInUp">
                <div className="panel-group" id="accordion">
                  <Accordion />
                </div>
              </div>
            </div>
          </div>
        </section>{' '}
      </LazyLoad>
    </Suspense>
  );
};

export default Features;
