import React from 'react';

const SocialButtons = ({ socialMediaIcons }) => (
  <>
    {socialMediaIcons.map((item, index) => (
      <li key={index + new Date().getTime()}>
        <a href={item.url} target="_blank" rel="noopener noreferrer">
          <span>
            <i className={item.icon} />
          </span>
        </a>
      </li>
    ))}
  </>
);

export default SocialButtons;
