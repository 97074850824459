import React, { useEffect, useState } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import SocialButtons from './SocialButtons';
import { apiUrl, siteUrl } from '../utils/constants';

const axios = require('axios');

const Contact = () => {
  const [data, setData] = useState([]);
  const [bgData, setBgData] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      axios.get(`${apiUrl}media`).then((result) => {
        setData(result.data);
      });

      axios.get(`${apiUrl}background&section_id=8`).then((result) => {
        setBgData(result.data[0]);
      });
    }
  });

  if (bgData.length === 0) {
    return '';
  }

  return (
    <LazyLoadComponent>
      <section
        id="contact"
        style={{
          background: `url(${siteUrl}${bgData.img}) center center no-repeat`,
          backgroundSize: 'cover',
        }}
      >
        <div className="overlay" />
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-12 wp11 delay-1s animated fadeInDown">
              <ul className="social-buttons">
                <SocialButtons socialMediaIcons={data} />
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="copyright">
              © Created With <i className="fa fa-heart" />
              <a href="https://www.koidaweb.com"> Koida</a>
              <br />
            </div>
            <div className="footer-line" />
          </div>
        </div>
      </section>
    </LazyLoadComponent>
  );
};

export default Contact;
