import React, { lazy, Suspense } from 'react';
import RenderLoader from './common/RenderLoader';

const LazyImg = lazy(() => import('./LazyImg'));

const Img = ({ src, height, width = '' }) => (
  <Suspense fallback={RenderLoader()}>
    <LazyImg src={src} height={height} width={width} />
  </Suspense>
);

export default Img;
