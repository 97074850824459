import React, { useEffect, useState } from 'react';
import NavUrl from './NavUrl';
import { apiUrl } from '../utils/constants';

const axios = require('axios');

const Nav = () => {
  const myInput = React.createRef();

  const [currentTab, setCurrentTab] = useState(0);
  const [navStyle, setNavStyle] = useState('');
  const [data, setData] = useState([]);
  const [clientName, setClientName] = useState('');

  const collapseNav = () => {
    if (navStyle === '' && window.scrollY > 100) {
      setCurrentTab(1);
      setNavStyle('top-nav-collapse');
    } else if (navStyle !== '' && window.scrollY < 100) {
      setCurrentTab(0);
      setNavStyle('');
    }
  };

  useEffect(() => {
    if (data.length === 0) {
      axios.get(`${apiUrl}navigation`).then((result) => {
        setData(result.data);
      });
    } else if (clientName.length === 0) {
      axios.get(`${apiUrl}sites`).then((result) => {
        setClientName(result.data[0]);
      });
    } else {
      window.addEventListener('scroll', collapseNav);

      return () => {
        window.removeEventListener('scroll', collapseNav);
      };
    }
  });

  let logoName = '';
  let firstWord = [];
  let secondWord = [];
  if (clientName.name_1) {
    logoName = clientName.name_1;

    [firstWord, secondWord] = logoName.split(' ');
  }

  return (
    <nav
      className={`navbar navbar-custom navbar-fixed-top wp1 animated fadeInDown ${navStyle}`}
      role="navigation"
      ref={myInput}
    >
      <div className="container">
        <div className="navbar-header page-scroll">
          <button
            type="button"
            className="navbar-toggle"
            data-toggle="collapse"
            data-target=".navbar-main-collapse"
          >
            <i className="fa fa-bars" />
          </button>
          <a className="navbar-brand" href="#page-top">
            {firstWord} <span className="font-light">{secondWord}</span>
          </a>
        </div>

        <div className="collapse navbar-collapse navbar-right navbar-main-collapse">
          <ul className="nav navbar-nav">
            <li className="hidden">
              <a href="#page-top">test</a>
            </li>
            <NavUrl navSections={data} currentTab={currentTab} />
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
