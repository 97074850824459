import React, { useEffect, useState, lazy, Suspense } from 'react';
import RenderLoader from './common/RenderLoader';
import { apiUrl } from '../utils/constants';

const CountUp = lazy(() => import('react-countup'));

const axios = require('axios');

const Slider = () => {
  const myInput = React.createRef();
  const [data, setData] = useState([]);

  const animateClass = (arrayLength) => {
    const section = myInput.current;
    if (
      section != null &&
      section.offsetTop - window.scrollY < section.offsetHeight / 2
    ) {
      for (let index = 1; index <= arrayLength; index += 1) {
        const counter = document.querySelector(`.expand-${index}`);
        document.querySelector(`.expand-${index}`).style.width = `${parseInt(
          counter.title
        )}%`;
      }
    }
  };

  useEffect(() => {
    if (data.length === 0) {
      axios.get(`${apiUrl}slider_bar`).then((result) => {
        setData(result.data);
      });
    } else {
      animateClass(data.length);
    }
  });

  return (
    <>
      {data.map((item, index) => (
        <li key={`slider${index + new Date().getTime()}`} ref={myInput}>
          <h6>{item.heading_1}</h6>
          <div>
            <span
              className={`red-arrow expand expand-${index + 1}`}
              style={{ width: 0 }}
              title={item.number}
            >
              <em>
                <small id={`expand-counter-${index + 1}`}>
                  <Suspense fallback={RenderLoader()}>
                    <CountUp
                      end={parseInt(item.number)}
                      duration={10}
                      useEasing
                    />
                  </Suspense>
                </small>
              </em>
            </span>
          </div>
        </li>
      ))}
    </>
  );
};

export default Slider;
