import React, { useEffect, useState } from 'react';
import SectionTitle from './SectionTitle';
import Actor from './Actor';
import TextOnly from './TextOnly';

import { apiUrl } from '../utils/constants';

const axios = require('axios');

const Actors = () => {
  const [data, setData] = useState([]);
  const [preamble, setPreamble] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      axios.get(`${apiUrl}team`).then((result) => {
        setData(result.data);
      });
    } else if (preamble.length === 0) {
      axios.get(`${apiUrl}preambles&section_id=2&page=team`).then((result) => {
        setPreamble(result.data[0]);
      });
    } else {
      $('#owl-actors').owlCarousel({
        itemsCustom: [
          [0, 1],
          [450, 2],
          [600, 2],
          [700, 2],
          [1000, 3],
          [1200, 3],
          [1400, 3],
          [1600, 3],
        ],
        navigation: true,
        pagination: true,
        mouseDrag: true,
      });
    }
  });

  return (
    <section id="actors">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <SectionTitle
              headingText={preamble.heading_1}
              animationStyle="animated fadeInDown"
            />

            <div id="owl-actors" className="owl-carousel owl-theme">
              <Actor actorsList={data} animationStyle="animated fadeInDown" />
            </div>
          </div>
        </div>
      </div>

      <TextOnly />
    </section>
  );
};
export default Actors;
