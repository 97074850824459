import React, { useEffect, useState } from 'react';
import { apiUrl } from '../utils/constants';

const axios = require('axios');

const Accordion = () => {
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(-1);
  const [collapse, setCollapse] = useState('collapsed');

  useEffect(() => {
    if (data.length === 0) {
      axios
        .get(`${apiUrl}text_only&section_id=2`)
        .then((result) => setData(result.data));
    }
  });

  const changeCollapse = () => {
    if (collapse === 'collapsed') {
      setCollapse('');
    } else if (collapse === '') {
      setCollapse('collapsed');
    }
  };

  const changeTab = (index) => {
    setActiveTab(index);
    changeCollapse();
  };

  return (
    <>
      {data.map((item, index) => (
        <div
          key={`accordion${index}${new Date().getTime()}`}
          className="panel panel-default"
        >
          <div className="panel-heading">
            <h4 className="panel-title">
              <a
                aria-expanded={
                  activeTab === index && collapse === 'collapsed'
                    ? 'true'
                    : 'false'
                }
                className={`accordion-toggle ${collapse}`}
                data-toggle="collapse"
                data-parent="#accordion"
                href={`#tabs${index}`}
                onClick={() => changeTab(index)}
              >
                <span
                  className={`glyphicon ${
                    activeTab === index && collapse !== 'collapsed'
                      ? 'glyphicon-minus'
                      : 'glyphicon-plus'
                  }`}
                />
                {item.heading_1}
              </a>
            </h4>
          </div>
          <div
            id={`tabs${index}`}
            aria-expanded={
              activeTab === index && collapse === 'collapsed' ? 'true' : 'false'
            }
            className={`panel-collapse collapse ${
              activeTab === index && collapse === 'collapsed' ? 'in' : ''
            }`}
          >
            <div className="panel-body">
              {item.text_1.split('\r\n').map((crrStr, subIndex) => (
                <React.Fragment
                  key={`AccordionP${subIndex}${new Date().getTime()}`}
                >
                  {crrStr}
                  <br />
                </React.Fragment>
              ))}
              <br /> <br />
              <a href={item.item_url} target="_blank" rel="noopener noreferrer">
                {item.item_url}
              </a>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Accordion;
