import React, { useEffect, useState, lazy, Suspense } from 'react';
import RenderLoader from './common/RenderLoader';
import { apiUrl } from '../utils/constants';

const axios = require('axios');

const CountUp = lazy(() => import('react-countup'));

const Numbers = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      axios.get(`${apiUrl}numbers`).then((result) => {
        setData(result.data);
      });
    }
  });

  return (
    <Suspense fallback={RenderLoader()}>
      {data.map((item, index) => (
        <li key={`numbers${index}`}>
          <CountUp end={parseInt(item.number)} duration={5} />

          <h5>{item.heading_1}</h5>
        </li>
      ))}
    </Suspense>
  );
};

export default Numbers;
