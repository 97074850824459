import React, { useEffect, useState, lazy, Suspense } from 'react';
import RenderLoader from './common/RenderLoader';
import Movie from './Movie';
import SectionTitle from './SectionTitle';

import { apiUrl, siteUrl } from '../utils/constants';

const axios = require('axios');

const LazyLoad = lazy(() => import('react-lazyload'));

const MoviePlayer = () => {
  const myInput = React.createRef();

  const [bgData, setBgData] = useState([]);
  const [preambles, setPreambles] = useState([]);

  useEffect(() => {
    if (bgData.length === 0) {
      axios.get(`${apiUrl}background&section_id=2`).then((result) => {
        setBgData(result.data[0]);
      });
    } else if (preambles.length === 0) {
      axios.get(`${apiUrl}preambles&section_id=2&page=video`).then((result) => {
        setPreambles(result.data[0]);
      });
    }
  });

  if (bgData.length === 0) {
    return '';
  }

  return (
    <Suspense fallback={RenderLoader()}>
      <LazyLoad>
        <section
          id="movie-player"
          ref={myInput}
          style={{
            background: `url(${siteUrl}${bgData.img}) no-repeat center center`,
            backgroundSize: 'cover',
          }}
        >
          <div className="overlay" />
          <div className="open_dampers" />
          <div className="open_dampers" />
          <div className="container">
            <div className="col-lg-10 col-lg-offset-1 wp5 delay-05s animated fadeInUp">
              <SectionTitle headingText={preambles.heading_1} />
              <Movie />
            </div>
          </div>
        </section>
      </LazyLoad>
    </Suspense>
  );
};

export default MoviePlayer;
