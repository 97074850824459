import React, { useEffect, useState, lazy, Suspense } from 'react';
import RenderLoader from './common/RenderLoader';
import { apiUrl } from '../utils/constants';

const LazyLoad = lazy(() => import('react-lazyload'));

const axios = require('axios');

const TextOnly = () => {
  const [data, setData] = useState([]);
  const [preambles, setPreambles] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      axios.get(`${apiUrl}text_only&section_id=4`).then((result) => {
        setData(result.data);
      });
    } else if (preambles.length === 0) {
      axios
        .get(`${apiUrl}preambles&section_id=4&page=text_only`)
        .then((result) => {
          setPreambles(result.data[0]);
        });
    }
  });

  return (
    <Suspense fallback={RenderLoader()}>
      <LazyLoad>
        <div className="advantages">
          <div className="section-sub-title">{preambles.heading_1}</div>

          <div className="colored-line" />
          <div className="container">
            {data.map((item, index) => {
              let delayTime = '';

              if (index + 1 === 2) {
                delayTime = 'delay-05s';
              } else if (index + 1 === 3) {
                delayTime = 'delay-1s';
              } else if (index + 1 > 3 || index + 1 === 1) {
                delayTime = '';
              }

              return (
                <div
                  key={`textOnly${index}${new Date().getTime()}`}
                  className={`col-md-4 advantages-item wp7 ${delayTime} animated fadeInDown`}
                >
                  {item.text_1
                    .split(/(?:\r\n|\r|\n)/g)
                    .map((paragraph, subIndex) => (
                      <p
                        key={`numbers${
                          index + subIndex
                        }${new Date().getTime()}`}
                        className="justified_text"
                      >
                        {paragraph}
                      </p>
                    ))}
                </div>
              );
            })}
          </div>
        </div>
      </LazyLoad>
    </Suspense>
  );
};

export default TextOnly;
