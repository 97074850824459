import React, { useEffect, useState } from 'react';
import { apiUrl } from '../utils/constants';

const axios = require('axios');

const IconText = () => {
  const myInput = React.createRef();
  const [data, setData] = useState([]);
  const [preambles, setPreambles] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      axios.get(`${apiUrl}text_icon&section_id=3`).then((result) => {
        setData(result.data);
      });
    } else if (preambles.length === 0) {
      axios
        .get(`${apiUrl}preambles&section_id=3&page=text_icon`)
        .then((result) => {
          setPreambles(result.data[0]);
        });
    }
  });

  return (
    <section id="actors" ref={myInput}>
      <div className="advantages">
        <div className="section-sub-title">{preambles.heading_1}</div>
        <div className="colored-line" />

        <div className="container">
          {data.map((item, index) => (
            <div
              key={`iconText${index + new Date().getTime()}`}
              className="col-md-6 advantages-item wp7 delay-05 animated fadeInDown"
            >
              <div className="advantages-item-icon">
                <i className={item.icon} />
              </div>
              <h3>{item.heading_1}</h3>
              <p>{item.text_1}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default IconText;
