import React, { useEffect, useState, lazy, Suspense } from 'react';
import { isMobile } from 'react-device-detect';
import RenderLoader from './common/RenderLoader';
import TextCarousel from './TextCarousel';
import SectionTitle from './SectionTitle';
import { apiUrl, siteUrl } from '../utils/constants';

const axios = require('axios');

const LazyLoad = lazy(() => import('react-lazyload'));

const Intro = () => {
  const [data, setData] = useState([]);
  const [preambles, setPreambles] = useState([]);

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };

    if (data.length === 0) {
      axios.get(`${apiUrl}home`).then((result) => {
        setData(result.data);
      });
    } else if (preambles.length === 0) {
      axios.get(`${apiUrl}preambles&section_id=1`).then((result) => {
        setPreambles(result.data[0]);
      });
    } else {
      $('#owl-intro').owlCarousel({
        navigation: true,
        slideSpeed: 300,
        paginationSpeed: 400,
        singleItem: true,
        pagination: true,
      });
    }
  });

  return (
    <section id="intro">
      <div className="video-content">
        <div className="vimeo-wrapper">
          {!isMobile && data.length > 0 ? (
            <Suspense fallback={RenderLoader()}>
              <LazyLoad>
                <video autoPlay muted loop>
                  <source src={`${siteUrl}bgVideo.mp4`} type="video/mp4" />
                </video>
              </LazyLoad>
            </Suspense>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="overlay">
        <div className="container-wrapper">
          <div className="container">
            <div className="col-md-12 animated">
              <div className="intro-info-wrapper">
                <SectionTitle headingText={preambles.heading_1} span />

                <div id="owl-intro" className="owl-carousel owl-theme">
                  <TextCarousel
                    span="false"
                    textSlide={data}
                    customClass="font-similar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
