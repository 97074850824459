import React from 'react';
import Img from './Img';
import { siteUrl } from '../utils/constants';

const Actor = ({ actorsList, animationStyle }) => (
  <>
    {actorsList.length !== 0
      ? actorsList.map((item, index) => (
          <div key={`actor${index + new Date().getTime()}`} className="item">
            <div className={`col-md-12 wp6 delay-05s ${animationStyle}`}>
              <div className="actor">
                <div className="avatar">
                  <Img src={`${siteUrl}crop_1_${item.img}`} height={490} />
                </div>
              </div>
              <div className="actor-info">
                <h4 className="heading">{`${item.name}`}</h4>
                <h5 className="sub-heading">
                  {item.position_1} <br />
                  {item.text_1}
                </h5>
              </div>
            </div>
          </div>
        ))
      : ''}
  </>
);

export default Actor;
