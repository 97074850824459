import React from 'react';

const Row = ({ rowData }) => (
  <>
    {rowData.split('\r\n').map((crrStr, index) => (
      <tr className="white_text" key={`row${index}${new Date().getTime()}`}>
        <td>{crrStr.split(':')[0]}</td>
        <td>&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;</td>
        <td>{crrStr.split(':')[1]}</td>
      </tr>
    ))}
  </>
);

export default Row;
