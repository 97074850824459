import React from 'react';

const Text = ({ iconList }) => (
  <>
    {iconList.map((item, index) => (
      <li key={`text${index}${new Date().getTime()}`}>
        <i className={item.icon} /> {item.text_1}
      </li>
    ))}
  </>
);

export default Text;
