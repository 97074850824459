import React, { useEffect, useState, lazy, Suspense } from 'react';
import RenderLoader from './common/RenderLoader';
import Img from './Img';
import Row from './Row';
import SectionTitle from './SectionTitle';
import { apiUrl, siteUrl } from '../utils/constants';

const LazyLoad = lazy(() => import('react-lazyload'));

const axios = require('axios');

const About = () => {
  const [data, setData] = useState([]);
  const [preambles, setPreambles] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      axios.get(`${apiUrl}about`).then((result) => {
        setData(result.data);
      });
    } else if (preambles.length === 0) {
      axios.get(`${apiUrl}preambles&section_id=2`).then((result) => {
        setPreambles(result.data[0]);
      });
    }
  });

  return (
    <Suspense fallback={RenderLoader()}>
      <LazyLoad>
        <section id="about">
          <div className="more page-scroll" id="more">
            <a href="#about">
              <i className="fa fa-angle-down" />
            </a>
          </div>

          <div className="container wp2 animated fadeInDown">
            <SectionTitle headingText={preambles ? preambles.heading_1 : ''} />
            {data.map((item, index) => (
              <div key={`about${index}${new Date().getTime()}`} className="row">
                <div className="col-lg-6 wp2 delay-2s animated fadeInDown">
                  <div className="movie-avatar">
                    <div className="movie-avatar-img">
                      <Img
                        src={`${siteUrl}${item.img}`}
                        height={512}
                        width={960}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 wp2 delay-2s animated fadeInDown">
                  <SectionTitle
                    headingText={preambles[1] ? preambles[1].heading_1 : ''}
                    headingTwo
                  />
                  <h4 className="sub-text">
                    {preambles[1] ? preambles[1].text_1 : ''}
                  </h4>

                  <table className="text-dark">
                    <tbody>
                      <Row rowData={data[0].list_1} />
                    </tbody>
                  </table>
                  <br />

                  <p className="white_text">{item.text_1}</p>
                  <br />

                  <div className="detailed">
                    Rating:&nbsp;&nbsp;
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star-half-o" />
                    <span>
                      <b> 4.5</b>
                    </span>
                  </div>
                  <p />
                </div>
              </div>
            ))}
          </div>
        </section>
      </LazyLoad>
    </Suspense>
  );
};

export default About;
