import React, { useState } from 'react';

const NavUrl = ({ navSections, currentTab }) => {
  const [activeTab, setActiveTab] = useState(currentTab);

  if (currentTab === 0 && activeTab !== 0) {
    setActiveTab(0);
  }
  const orderedNavOne = navSections.filter((item) => {
    if (item.section !== 'services' && item.section !== 'blog') {
      return item;
    }

    return '';
  });

  const orderedNavTwo = navSections.filter((item) => {
    if (item.section === 'services') {
      return item;
    }
    if (item.section === 'blog') {
      return item;
    }
    if (item.section === 'contact') {
      return item;
    }
    return '';
  });

  return (
    <>
      {orderedNavOne.map((item, index) => {
        if (index === 0) {
          return (
            <li key={`nav${index}${new Date().getTime()}`} className="hidden">
              <a href="#page-top" onClick={() => setActiveTab(index)}>
                {item.name_1}
              </a>
            </li>
          );
        }

        let tag = item.section;

        if (item.section === 'testimonials') {
          tag = 'actors';
        }

        if (item.section === 'contact') {
          return orderedNavTwo.map((section, indexTwo) => {
            let tagUrl = section.section;

            if (section.section === 'services') {
              tagUrl = 'bio';
            }
            return (
              <li
                key={`navTwo${indexTwo + 5}${new Date().getTime()}`}
                className={`page-scroll ${
                  indexTwo + 5 === activeTab ? 'active' : ''
                }`}
              >
                <a
                  href={`#${tagUrl}`}
                  onClick={() => setActiveTab(indexTwo + 5)}
                >
                  {section.name_1}
                </a>
              </li>
            );
          });
        }

        return (
          <li
            key={`nav${index}${new Date().getTime()}`}
            className={`page-scroll ${index === activeTab ? 'active' : ''}`}
          >
            <a href={`#${tag}`} onClick={() => setActiveTab(index)}>
              {item.name_1}
            </a>
          </li>
        );
      })}
    </>
  );
};

export default NavUrl;
