import React from 'react';
import Nav from './components/Nav';
import Intro from './components/Intro';
import About from './components/About';
import AboutSub from './components/AboutSub';
import Features from './components/Features';
import MoviePlayer from './components/MoviePlayer';
import Actors from './components/Actors';
import Gallery from './components/Gallery';
import Reviews from './components/Reviews';
import Author from './components/Author';
import IconText from './components/IconText';
import Blog from './components/Blog';
import Contact from './components/Contact';

const App = () => (
  <>
    <Nav />
    <Intro />
    <About />
    <AboutSub />
    <Features />
    <MoviePlayer />
    <Actors />
    <Gallery />
    <Reviews />
    <Author />
    <IconText />
    <Blog />
    <Contact />
  </>
);

export default App;
