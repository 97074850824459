import React, { useEffect, useState, lazy, Suspense } from 'react';
import RenderLoader from './common/RenderLoader';
import { apiUrl } from '../utils/constants';

const axios = require('axios');

const Vimeo = lazy(() => import('@u-wave/react-vimeo'));

const Movie = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      axios.get(`${apiUrl}video&section_id=2`).then((result) => {
        setData(result.data);
      });
    }
  });

  return (
    <>
      {data.map((item, index) => (
        <div
          key={`movie${index}${new Date().getTime()}`}
          className="embed-responsive embed-responsive-16by9"
        >
          <Suspense fallback={RenderLoader()}>
            <Vimeo
              video={item.url.replace('https://player.vimeo.com/video/', '')}
            />
          </Suspense>
        </div>
      ))}
    </>
  );
};

export default Movie;
