import React from 'react';
import Img from './Img';

import { siteUrl } from '../utils/constants';

const ImgUrl = ({ imgLinks }) => (
  <>
    {imgLinks.map((item, index) => (
      <div key={`gallery${index}${new Date().getTime()}`} className="item">
        <a href={`${siteUrl}${item.img}`} className="popup">
          <Img src={`${siteUrl}crop_2_${item.img}`} alt="" />
        </a>
      </div>
    ))}
  </>
);

export default ImgUrl;
