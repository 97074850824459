import React from 'react';

const TextCarousel = ({ customClass, textSlide }) => (
  <>
    {textSlide.map((item, index) => (
      <div key={`textCarousel${index}${new Date().getTime()}`} className="item">
        <p className={customClass !== '' ? customClass : ''}>{item.text_1}</p>
      </div>
    ))}
  </>
);

export default TextCarousel;
